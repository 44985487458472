import React, { useState } from 'react'

import { useHistory, withRouter, Switch, Route } from 'react-router-dom'
import custImage from '../../assets/custImage.png'

import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tabs, Image, Columns, Button, Section } from 'react-bulma-components/dist';
import PageHeader from '../../containers/PageHeader'
import { pages, departments, screen, departmentForScreen, clientScreenTab, MeasurementType, picType, PrintSheetType } from '../../utilities/enums'

import { showOnlyClientFeature } from '../../ClientInfo/clientInfo';

import ColumnAux from '../../containers/ColumnAux'
import Aux from '../../containers/Aux'
import { canAddOrderAndProducts, Customer } from '../../utilities/Classes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import ClientInfoForm from '../../containers/ClientInfoForm'
import ClientMeasurementsInfo from '../../containers/ClientMeasurementsInfo'
import ClientOrdersInfo from '../../containers/ClientOrdersInfo'
import ProductsOfOrder from '../../containers/ProductsOfOrder'
import ProductModal from '../../components/Cards/ProductModal'
import OrderModal from '../../components/Cards/OrderModal'
import CustomerSearchModal from '../../components/Cards/CustomerSearchModal'
import ImageUploader from '../../containers/ImageUploader'
import ImageViewer from '../../containers/ImageViewer'
import MeasurementModal from '../../containers/MeasurementModal'
import MeasurementTemplates from '../../containers/MeasurementTemplates'
import cogoToast from 'cogo-toast';
import PostureModal from '../../containers/PostureModal';
import PatternModal from '../../containers/PatternModal';
import PrintOrder from '../../containers/PrintOrder';


const Client = (props) => {

    const [searchesNeeded, setSearchesNeeded] = useState({
        imageSearchNeeded: props.isEmbedded ? true : false,
        measurementSearchNeeded: props.isEmbedded ? true : false,
        orderSearchNeeded: props.isEmbedded ? true : false
    })
    const [customer, setCustomer] = useState(props.customer ?? null)
    const [customerImages, setCustomerImages] = useState([])
    const [customerOrders, setCustomerOrders] = useState([])
    const [customerMeasurements, setCustomerMeasurements] = useState([])

    const [phoneFromSearch, setPhoneFromSearch] = useState(null)

    const [searchCustomer, setSearchCustomer] = useState(props.isEmbedded ? false : true)
    const [currentDept, setCurrentDept] = useState(departments.Store)
    const [currentScreen, setCurrentScreen] = useState(screen.NewOrder)
    const [currentTab, setCurrentTab] = useState(clientScreenTab.info)
    const [orderSelected, setOrderSelected] = useState(null)
    const [productSelected, setProductSelected] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [measurementSelected, setMeasurementSelected] = useState(null)

    const [addImage, setAddImage] = useState(false)
    const [addProfilePic, setAddProfilePic] = useState(false)

    const [addMeasurement, setAddMeasurement] = useState(false)
    const [editPostures, setEditPostures] = useState(false)
    const [useMeasurementFromTemp, setUseMeasurementFromTemp] = useState(null)
    const [newPosture, setNewPosture] = useState(false)
    const [newPattern, setNewPattern] = useState(false)
    const [newOrder, setNewOrder] = useState(false)
    const [newProduct, setNewProduct] = useState(false)

    const [addPattern, setAddPattern] = useState(false)
    const [editPatternAtIndex, setEditPatternAtIndex] = useState(null)

    let [isUpdatingCust, setIsUpdatingCust] = useState(false)

    const [printCustomer, setPrintCustomer] = useState(false)


    let isNewCustomer = true

    let justAddedProfilePicUrl = null


    let pathOrder = () => {
        let path = props.location.pathname
        let links = path.split('/')

        // removing initial blank item after split
        links.shift()

        return links
    }



    const setNoSelected = () => {
        setOrderSelected(null)
        setProductSelected(null)
        setNewOrder(false)
        setNewPattern(false)
        setNewPosture(false)
        setNewProduct(false)
        setAddMeasurement(false)
        setEditPostures(false)
        setSelectedImage(null)
        setAddImage(false)
        setAddProfilePic(false)
        setMeasurementSelected(null)
        setUseMeasurementFromTemp(null)
        setAddPattern(false)
        setEditPatternAtIndex(null)
        setPrintCustomer(false)
    }


    const changeTab = (tab) => {


        if (!customer || customer === null) {
            cogoToast.warn('You need to create and save Client first.')
            return
        }

        if (customer.objectId == null) {
            cogoToast.warn('You need to save Client first.')
            return
        }
        setCurrentTab(tab)
    }

    let tabs = (

        <Tabs
            //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
            fullwidth={true}
            align='centered'
            size='small'
            className="boldTabs has-text-black marginTop10"
        >
            <Tabs.Tab active={currentTab === clientScreenTab.info} onClick={() => { changeTab(clientScreenTab.info) }} >  Profile </Tabs.Tab>
            <Tabs.Tab className={customer ? ' has-text-semibold ' : ' has-text-semibold has-text-grey-light '} active={currentTab === clientScreenTab.measurements} onClick={() => { changeTab(clientScreenTab.measurements) }} >  Measurements </Tabs.Tab>

            {
                showOnlyClientFeature ? null : <Tabs.Tab active={currentTab === clientScreenTab.orders} onClick={() => { changeTab(clientScreenTab.orders) }} >  Orders </Tabs.Tab>
            }


        </Tabs>

    )


    const setCustOrders = (orders) => {
        let sn = { ...searchesNeeded }
        sn.orderSearchNeeded = false
        setSearchesNeeded(sn)
        setCustomerOrders(orders)
    }

    const orderIsUpdated = (ord) => {
        let ords = [...customerOrders]

        const index = ords.findIndex((or) => {
            return or.objectId === ord.objectId
        })

        if (index != null) {
            ords[index] = ord
        }

        setCustomerOrders(ords)
    }

    



    const setCustImages = (images) => {
        let sn = { ...searchesNeeded }
        sn.imageSearchNeeded = false
        setSearchesNeeded(sn)
        if (customerImages.length !== images) {
            setCustomerImages(images)
        }
    }


    const measurementTemplateSelected = (tmp) => {
        console.log('MEASUREMENT TEMPLATE SELECTED = ')
        console.log(tmp)
        tmp.customer = customer
        setNoSelected()
        setUseMeasurementFromTemp(tmp)
    }

    const posturesChanged = (postures) => {
        let cust = Customer.copyFrom(customer)
        cust.selectedPostures = postures ?? []

        cust.update((succ, errMsg) => {
            if (succ) {
                cogoToast.success("Client Postures updated")
                setCustomer(cust)
            } else {
                cogoToast.error("Some error occured while updating postures")
            }
            setEditPostures(false)
        })

    }

    const patternAdded = (pattern) => {

        let cust = Customer.copyFrom(customer)

        let pttr = cust.customMadePatterns ?? []

        pttr.push(pattern)



        console.log(">>>>SETTING CUSTOM MADE PATTERNS TO")
        console.log(pttr)

        cust.customMadePatterns = pttr

        cust.update((succ, errMsg) => {
            if (succ) {
                cogoToast.success("Pattern Added")
                console.log(">>>>CUSTOM PATTERNS ARE SAVED... NOW CUSTOMER IS")
                console.log(cust)
                setCustomer(cust)
            } else {
                cogoToast.error("Some error occured while adding pattern")
            }
            setAddPattern(false)
        })
    }

    const patternChanged = (pattern, isDeleted) => {
        let pttr = [...customer.customMadePatterns ?? []]
        if (editPatternAtIndex) {
            let index = editPatternAtIndex.index ?? null;
            if (index !== null) {
                if (isDeleted === true) {
                    // delete at index 
                    console.log("Pattern delete index is = ", isDeleted)
                    pttr.splice(index, 1);
                    console.log("After Splice is ")
                    console.log(pttr)

                } else {
                    pttr[index] = pattern
                }
            }

            let cust = Customer.copyFrom(customer)
            cust.customMadePatterns = pttr
            cust.update((succ, errMsg) => {
                if (succ) {
                    cogoToast.success("Pattern Updated")
                    setCustomer(cust)
                } else {
                    cogoToast.error("Some error occured while updating pattern")
                }
                setEditPatternAtIndex(null)
            })
        }
    }


    const addMeasurementPressed = () => {
        setAddMeasurement(true)
    }

    const editPosturePressed = () => {
        setEditPostures(true)
    }

    const addPatternPressed = () => {
        setAddPattern(true)
    }

    const editPatternPressed = (editPatternWithIndex) => {
        // expected = {pattern:PATTERN, index: INDEX}
        setEditPatternAtIndex(editPatternWithIndex)
    }

    const setCustMeasurements = (mts) => {
        let sn = { ...searchesNeeded }
        sn.measurementSearchNeeded = false
        setSearchesNeeded(sn)
        if (customerMeasurements.length !== mts) {
            setCustomerMeasurements(mts)
        }
    }

    const custMeasurmentUpdated = (mObj) => {
        let sn = [...customerMeasurements]

        const index = sn.findIndex((mst) => {
            return mst.objectId === mObj.objectId
        })

        if (index != null) {
            sn[index] = mObj
        }

        setCustomerMeasurements(sn)
        setNoSelected()
    }

    const measurementRemoved = (mObj) => {
        let sn = [...customerMeasurements]

        let newCMs = sn.filter((mst) => {
            return mst.objectId !== mObj.objectId
        })

        setCustomerMeasurements(newCMs)
        setNoSelected()
    }


    const custMeasurementAdded = (mObj) => {
        let sn = [...customerMeasurements]
        sn.push(mObj)
        setCustomerMeasurements(sn)
        setNoSelected()
    }




    const custFound = (cust) => {
        isNewCustomer = false

        let sn = { ...searchesNeeded }
        sn.imageSearchNeeded = true
        sn.measurementSearchNeeded = true
        sn.orderSearchNeeded = true

        setSearchesNeeded(sn)

        setCustomer(cust)
        toggleSearchCustomer()
        cogoToast.info(`Found ${cust.name}`)
    }

    const custWasNotFound = (phoneData) => {
        isNewCustomer = true

        let sn = { ...searchesNeeded }
        sn.imageSearchNeeded = true
        sn.measurementSearchNeeded = true
        sn.orderSearchNeeded = true


        if (phoneData !== null) {
            if (phoneData.length) {
                setPhoneFromSearch(phoneData)
            }
        } else {
            setPhoneFromSearch(null)
        }

        setCustomer(null)

        setSearchesNeeded(sn)

        toggleSearchCustomer()
        cogoToast.warn(`No client with this info`)
    }

    const toggleSearchCustomer = () => {
        setSearchCustomer(!searchCustomer)
    }



    const togglePrintCustomer = (val) => {
        setPrintCustomer(val ?? !printCustomer)
    }

    const custSaved = (cust) => {
        if (props.customerUpdated != null) {
            props.customerUpdated(cust)
        }
        setCustomer(cust)
    }

    const errorOccured = (msg) => {
        // cogoToast.warn(msg)
        // alert(msg)
    }

    const profileSection = () => {
        return (

            <Columns >

                <Columns.Column >
                </Columns.Column>

                <Columns.Column className="is-narrow" >
                    <ClientInfoForm customer={customer} phoneFromSearch={phoneFromSearch} customerSaved={custSaved} errorOccured={errorOccured} />
                </Columns.Column>

                <Columns.Column >
                </Columns.Column>
            </Columns>

        )
    }


    const hwsUpdated = (hwsObject) => {
        let custCopy = Customer.copyFrom(customer)
        custCopy.height = hwsObject.height
        custCopy.heightUnit = hwsObject.heightUnit
        custCopy.weight = hwsObject.weight
        custCopy.weightUnit = hwsObject.weightUnit
        custCopy.shoeSize = hwsObject.shoeSize
        custCopy.shoeSizeUnit = hwsObject.shoeSizeUnit

        setIsUpdatingCust(true)
        custCopy.update((succ, errMsg) => {
            setIsUpdatingCust(false)
            if (succ) {
                cogoToast.success("Height, Weight, ShoeSize are updated.")
                if (props.customer) {
                    props.customerUpdated(custCopy)
                } else {
                    setCustomer(custCopy)
                }
            } else {
                console.log("Error while trying to update HWS. Error = ", errMsg)
                cogoToast.error("Could not update HWS. Check internet and try again.")
            }
        })

    }

    const noteUpdate = (note) => {
        let custCopy = Customer.copyFrom(customer)
        custCopy.measurementNote = note ?? ""

        setIsUpdatingCust(true)
        custCopy.update((succ, errMsg) => {
            setIsUpdatingCust(false)
            if (succ) {
                cogoToast.success("Measurement Note updated.")
                if (props.customer) {
                    props.customerUpdated(custCopy)
                } else {
                    setCustomer(custCopy)
                }
            } else {
                console.log("Error while trying to update HWS. Error = ", errMsg)
                cogoToast.error("Could not update Measurement Note. Check internet and try again.")
            }
        })

    }

    const measurementSection = () => {

        // addImagePressed={} addMeasurementPressed={} addPosturePressed={} addPatternPressed={}

        return (
            <ColumnAux isMiddle={true} size={7} >
                <ClientMeasurementsInfo customer={customer} hwsUpdated={hwsUpdated} updateNote={noteUpdate} isUpdaingCust={isUpdatingCust} images={customerImages} measurements={customerMeasurements} customerImagesFetched={setCustImages} customerMeasurementsFetched={setCustMeasurements} imageSearchNeeded={searchesNeeded.imageSearchNeeded} measurementSearchNeeded={searchesNeeded.measurementSearchNeeded} addImagePressed={addImagePressed} addMeasurementPressed={addMeasurementPressed} editPosturesPressed={editPosturePressed} addPatternPressed={addPatternPressed} editPatternPressed={editPatternPressed} imageSelected={imageSelected} measurementIsSelected={measurementIsSelected} />
            </ColumnAux>
        )
    }

    const selectOrder = (ord) => {
        // Push to /newOrder/order=uyfyfuf

        console.log('ORDER WAS SELECTED = ')
        console.log(ord)

        setOrderSelected(ord)
        setCurrentScreen(screen.ProductsOfOrder)
    }

    const backFromOrder = () => {
        setOrderSelected(null)
        setCurrentScreen(screen.NewOrder)
    }

    const viewProductPressed = (productId) => {
        setProductSelected(productId)
    }

    const productClosed = () => {
        console.log('MODAL CLOSED ')
        setProductSelected(null)
        setCurrentScreen(screen.ProductsOfOrder)
    }


    const addOrder = () => {
        setNewOrder(true)
    }

    const newOrderAdded = (ord) => {
        let ords = [...customerOrders]
        ords.unshift(ord)
        setCustOrders(ords)
    }

    const orderWasUpdated = (ord) => {
        let ords = [...customerOrders]
        const index = ords.findIndex((or) => {
            return or.objectId === ord.objectId
        })

        if (index != null) {
            ords[index] = ord
        }
        setCustOrders(ords)
    }



    const addProduct = () => {
        setNewProduct(true)
    }

    const addImagePressed = () => {
        setAddImage(true)
    }

    const imageAdded = (customerPic, isArray) => {

        if (addImage) {
            let ci = [...customerImages] ?? []
            if (isArray || customerPic.length) {
                ci.push.apply(ci, customerPic)
                // if (ci.length){
                //     ci.concat(customerPic)
                // }else{
                //     ci = customerPic
                // }
            } else {
                ci.push(customerPic)
            }

            setCustomerImages(ci)
            setAddImage(false)
        } else if (addProfilePic) {
            justAddedProfilePicUrl = customerPic
            customer.profilePicUrl = customerPic
            setAddProfilePic(false)
        }

    }

    const imageSelected = (imgObj) => {
        setSelectedImage(imgObj)
    }

    const imageDeleted = (imgObj) => {
        // Find this image and remove
        let ppCopy = [...customerImages]
        let fileteredPP = ppCopy.filter((thisPP) => {
            return thisPP.url !== imgObj.url
        })

        setCustomerImages(fileteredPP)
    }

    const measurementIsSelected = (mtObj) => {
        setMeasurementSelected(mtObj)
    }

    const ordersSection = () => {
        return (
            <ColumnAux isMiddle={true} size={7} >
                <ClientOrdersInfo customer={customer} screen={props.screen ?? screen.NewOrder} orderSearchNeeded={searchesNeeded.orderSearchNeeded} orders={customerOrders} customerOrdersFetched={setCustOrders} selectOrder={selectOrder} addOrder={addOrder} />
            </ColumnAux>
        )
    }



    const currentTabScreen = () => {
        switch (currentTab) {
            case clientScreenTab.measurements: return measurementSection();
            case clientScreenTab.orders: return ordersSection();
            default: return profileSection();

        }

    }


    const customerPicPressed = () => {
        console.log('Customer Pic Pressed')
        if (customer) {
            if (customer.objectId) {
                setAddProfilePic(true)
            }
        }
    }


    const orderHeader = () => {

        let profilePicUrl = null



        if (customer) {

            if (customer.profilePicUrl != null) {
                if (customer.profilePicUrl !== '') {
                    profilePicUrl = customer.profilePicUrl
                }
            }

            if (justAddedProfilePicUrl != null) {
                profilePicUrl = justAddedProfilePicUrl

            } else if (customer.profilePicUrl == null && customer.name !== "") {
                profilePicUrl = 'https://ui-avatars.com/api/?color=909090&name=' + customer.name
            }
        }

        return (
            <Aux>
                <Heading size={5} className=' marginTop30 is-capitalized has-text-centered'> {customer ? customer.name : null}
                    {
                        customer ?
                            <Button className=" marginTopMinus4 marginLeft8 has-text-grey-light is-centered has-text-centered p-0 is-small  no-decoration is-text " onClick={togglePrintCustomer} > <FontAwesomeIcon className=' type74' icon={faFileAlt} /></Button>
                            : null
                    }
                </Heading>
                {customer ? (customer.customId ? <p className=" type55 has-text-centered has-text-weight-semibold has-text-grey marginTopMinus20 marginBottom10 "> {customer.customId}  </p> : null) : null}
                {/* <p className="has-text-centered"> <Button className=" mt-minus-10 m-0 mb-4 has-text-grey-light is-centered has-text-centered p-0 is-small  no-decoration is-text " onClick={togglePrintCustomer} > <FontAwesomeIcon className=' type74' icon={faFileAlt} /></Button> </p> */}

                {/* <Columns className="has-text-centered"> */}
                {/* <Columns.Column size={1} className="is-narrow has-image-centered"> */}
                <Image className=' customerImage has-image-centered marginBottom20' src={profilePicUrl ?? custImage} alt="Customer Profile Pic" size={64} onClick={customerPicPressed} />
                {/* <img src={custImage} alt="Customer Profile Pic" width="120" height="30" onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.welcome) }} /> */}

                {/* </Columns.Column> */}
                {/* <Columns.Column > */}
                {tabs}
                {/* </Columns.Column> */}
                {/* </Columns> */}
            </Aux>

        )
    }





    const thisPage = () => {
        if (orderSelected) {
            return <ProductsOfOrder order={orderSelected} customer={customer} fetchProducts={isNewCustomer} screen={props.screen ?? screen.NewOrder} viewProductPressed={viewProductPressed} addProduct={addProduct} backPressed={backFromOrder} orderUpdated={orderIsUpdated} />
        }

        return (
            <div>
                <Aux>
                    {orderHeader()}
                    <br />
                    {currentTabScreen()}
                </Aux>
            </div>

        )

    }




    return (
        <div>
            {props.isEmbedded ?
                (orderSelected ? null : <Button className="  no-decoration is-pulled-left is-small has-text-weight-bold" onClick={props.backPressed}><FontAwesomeIcon className='' icon={faChevronLeft} /></Button>)
                :
                <PageHeader crumbItems={pathOrder()} />}



            < br />


            {printCustomer ? <PrintOrder order={null} customer={customer} sheetType={PrintSheetType.customerSheet} products={[]} closed={togglePrintCustomer} /> : null}


            {(searchCustomer === true) ? <CustomerSearchModal customerFound={custFound} customerWasNotFound={custWasNotFound} errorOccured={errorOccured} closed={toggleSearchCustomer} /> : null}
            {(selectedImage !== null) ? <ImageViewer imgObj={selectedImage} type='customerPic' imageDeleted={imageDeleted} closed={setNoSelected} /> : null}
            {(measurementSelected !== null) ? <MeasurementModal purpose='edit' measurement={measurementSelected} type={MeasurementType.body} closed={setNoSelected} measurementUpadted={custMeasurmentUpdated} measurementRemoved={measurementRemoved} /> : null}
            {(useMeasurementFromTemp !== null) ? <MeasurementModal purpose='useTemplate' measurement={useMeasurementFromTemp} type={MeasurementType.body} closed={setNoSelected} templateUsed={custMeasurementAdded} /> : null}

            {(addProfilePic === true) ? <ImageUploader customer={customer} product={null} type={picType.CustomerProfilePic} closed={setNoSelected} imageAdded={imageAdded} /> : null}

            {(addImage === true) ? <ImageUploader customer={customer} product={null} type={picType.CustomerPic} closed={setNoSelected} imageAdded={imageAdded} /> : null}
            {(addMeasurement === true) ? <MeasurementTemplates type={MeasurementType.body} closed={setNoSelected} selectedTemplate={measurementTemplateSelected} isToSelectTemplate={true} /> : null}
            {(editPostures === true) ? <PostureModal selectedPostures={customer.selectedPostures} closed={setNoSelected} posturesChanged={posturesChanged} /> : null}

            {(addPattern === true) ? <PatternModal exitingPattern={null} closed={setNoSelected} patternAdded={patternAdded} /> : null}
            {editPatternAtIndex ? <PatternModal existingPattern={editPatternAtIndex} closed={setNoSelected} patternChanged={patternChanged} /> : null}

            {(newOrder === true) ? <OrderModal customer={customer} isNewOrder={true} order={null} orderAdded={newOrderAdded} orderUpdated={orderWasUpdated} closed={setNoSelected} /> : null}
            {(newProduct === true) ? <ProductModal customer={customer} isNewProduct={true} closed={setNoSelected} /> : null}
            {(productSelected !== null) ? <ProductModal customer={customer} screen={props.screen} isNewProduct={false} product={productSelected} closed={productClosed} /> : null}


            {thisPage()}
        </div>


    )

}

export default Client