import React from 'react';
import { Field } from 'formik'




///// ATAFO AFRICA /////

// Home Address !!!
// Add to Package.json AND also in .env
const homeDir = "https://atafo.tailorwise.com"

export const backAppId = "2g4SiDXrx8mXR8noVuDFYggHZV83hzFafomHhode"
export const backJSId = "qbJxD3YVXcF7fDuJfPXUqb1bBxOqiWgjTU2bJjD7"

export const defaultStoreObjid = "FC237G0HJG"  // Main Store
export const defaultUserObjid = "hywLGNUCi6"  // ROY

export const awsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/tw-main/"
export const clientName = "ATAFO"

export const clientPhone = "+91-09999999999999"
export const clientEmail = "info@tailorwise.com"
export const clientEmailFromId = "no-reply@tailorwise.com"  //TW no-reply-email or client validated email with us
export const clientCCEmail = ""

export const hasFactory = true
export const isSingleLocation = false
export const hasAgents = false
export const usesVendorViaFactory = true    // New Stage Factory_Recieved_For_Vendor added on 2-Aug-2021 - Also putting this as a flag in product as- usedVendorViaFactory & in business check usesVendorViaFactory

export const showOnlyClientFeature = false
export const useClientCid = true


// Specific Cofigurations

// Customer Info
export const config_cust_captureAddress = false
export const config_cust_captureProfession = false

export const clientWebsite = "https://tailorwise.com"
export const clientTermsAndConditionsUrl = "https://tailorwise.com/terms-conditions"

export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/Atafo/atafo-140x30.png"
export const clientMenuLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/Atafo/atafo-140x30.png"

export const currencySymbol = "$"
export const currencyCode = "USD"
export const clientBGHome = ""
export const clientBGHero = ""


export const client_defaultCountry = 'NG (+234)'
export const client_defaultCountryOption = () => {
    return (
        <option value={client_defaultCountry} >{client_defaultCountry}</option>
    )
}

export const client_favCountryList = () => {

    // FIRST OPTION SHOULD BE SAME AS DEFAULT COUNTRY
    return (
        <>
            {client_defaultCountryOption()}
            <option value='CM (+234)'  >CM (+234)</option>
            <option value='BJ (+229)'  >BJ (+229)</option>
            <option value='US (+1)'  >US (+1)</option>
            <option value='CA (+1)'  >CA (+1)</option>
            <option value='AU (+61)'  >AU (+61)</option>
            <option value='GB (+44)'  >GB (+44)</option>
            <option value='AE (+971)'  >AE (+971)</option>
            <option value='ZW (+263)'  >ZW (+263)</option>
            <option value='ZA (+27)' >ZA (+27)</option>
        </>
    )

}



//// PRODUCTS OFFERED 
export const ProductType = {

    // Sherwani: "SHERWANI",
    Suit2piece: "SUIT 2 PIECE",
    Suit3piece: "SUIT 3 PIECE",
    Coat: "COAT",
    TailCoat: "TAIL COAT",

    Blazer: "BLAZER",
    // SafariSuit: "SAFARI SUIT",

    Shirt: "SHIRT",
    KaftanShirt: "KAFTAN SHIRT",
    KaftanLongSleeve: "LONG SLEEVE KAFTAN",
    KaftanShortSleeve: "SHORT SLEEVE KAFTAN",
    KaftanJacket: "KAFTAN JACKET",
    KaftanSleeveless: "SLEEVELESS KAFTAN",

    ShacketLongSleeve: "LONG SLEEVE SHACKET",
    ShacketShortSleeve: "SHORT SLEEVE SHACKET",
    ShacketInner: "INNER SHACKET",
    BowTie: "BOWTIE",
    PocketSquare: "POCKET SQUARE",
    Corset: "CORSET",
    WeddingDress: "WEDDING DRESS",
    Omagbada: "OMAGBADA",
    Agbada: "AGBADA",
    CoWrap: "CO WRAP",
    BuWrap: "BU WRAP",

    Trouser: "TROUSER",
    WaistCoat: "WAIST COAT",
    // JawaharJacket: "JAWAHAR JACKET",
    // KurtaPyjama: "KURTA PYJAMA",
    // IndoWestern: "INDO WESTERN",
    // JODHPURI: "JODHPURI",
    // CowlKurta: "COWL KURTA",
    Tuxedoe: "TUXEDOE",
    //    case BlazerSports = "BLAZER SPORTS"
    //    case BlazerSafari = "BLAZER SAFARI"

    // Kurta: "KURTA",
    // Pyjama: "PYJAMA",
    StitchService: "STITCH SERVICE",
    Fabric: "FABRIC",
    None: 'None',
    Accessories: 'ACCESSORIES',
    NeckWear: 'NECKWEAR',
    Denim: 'DENIM',
    GolfTrouser: 'GOLF TROUSER',
    Jacket: 'JACKET',
    // BomberJacket: 'BOMBER JACKET',
    // FieldJacket: 'FIELD JACKET',
    // SportJacket: 'SPORT JACKET',
    OverCoat: 'OVER COAT',
    Polo: 'POLO',
    Pant: 'PANT',
    Bag: 'BAG',
    Shoes: 'SHOES',
    Senator : "SENATOR",
    CasualShirts : "CASUAL SHIRTS",
    CasualPants : "CASUAL PANTS",
    DanshikiWear : "DANSHIKI WEAR",
    AgbadaSet : "AGBADA SET",
    Shorts : "SHORTS",
    NativeCap : "NATIVE CAP",


    //    Stich Service (instead of MTO)
    //    Jawahar Jacket (instead of JAWAHAR JACKET)
    //    JODHPURI (instead of Bandhgala)
    //    Blazer (single)
    //    Safari Suit


    // NEW PRODUCTS ASKED BY ATAFO,
    TraditionalSuit: "TRADITIONAL SUIT",
    IsiAguSash: "ISI AGU SASH",
    IsiAguTop: "ISI AGU TOP",
    Safari: "SAFARI",
    JacketDress: "JACKET DRESS",
    // BowTie: "BOW TIE",
    CummerBand: "CUMMERBAND",


    // Traditional Suit
    // Isi Agu sash
    // Isi Agu top
    // Safari
    // Jacket dress
    // Bow tie
    // Cummerband
    

}


export const productType_allValues = () => {
    var products = []
    // <option>{ProductType.None}</option>
    products.push(ProductType.Tuxedoe)
    products.push(ProductType.Suit2piece)
    products.push(ProductType.Suit3piece)
    products.push(ProductType.Coat)
    products.push(ProductType.TailCoat)
    products.push(ProductType.Shirt)

    products.push(ProductType.KaftanShirt)
    products.push(ProductType.KaftanLongSleeve)
    products.push(ProductType.KaftanShortSleeve)
    products.push(ProductType.KaftanJacket)
    products.push(ProductType.KaftanSleeveless)

    products.push(ProductType.ShacketLongSleeve)
    products.push(ProductType.ShacketShortSleeve)
    products.push(ProductType.ShacketInner)


    products.push(ProductType.BowTie)
    products.push(ProductType.PocketSquare)
    products.push(ProductType.Corset)
    products.push(ProductType.WeddingDress)


    products.push(ProductType.Omagbada)
    products.push(ProductType.Agbada)
    products.push(ProductType.CoWrap)
    products.push(ProductType.BuWrap)


    products.push(ProductType.Trouser)
    products.push(ProductType.Blazer)
    products.push(ProductType.WaistCoat)
    // products.push(ProductType.Sherwani)
    // products.push(ProductType.IndoWestern)
    // products.push(ProductType.SafariSuit)
    // products.push(ProductType.KurtaPyjama)
    // products.push(ProductType.Kurta)
    // products.push(ProductType.Pyjama)
    // products.push(ProductType.CowlKurta)
    products.push(ProductType.StitchService)
    products.push(ProductType.Fabric)
    products.push(ProductType.Accessories)
    products.push(ProductType.NeckWear)
    products.push(ProductType.Denim)
    products.push(ProductType.GolfTrouser)
    products.push(ProductType.Jacket)
    // products.push(ProductType.BomberJacket)
    // products.push(ProductType.FieldJacket)
    // products.push(ProductType.SportJacket)
    products.push(ProductType.OverCoat)
    products.push(ProductType.Polo)
    products.push(ProductType.Pant)
    products.push(ProductType.Bag)
    products.push(ProductType.Shoes)

    products.push(ProductType.Senator)
    products.push(ProductType.CasualShirts)
    products.push(ProductType.CasualPants)
    products.push(ProductType.DanshikiWear)
    products.push(ProductType.AgbadaSet)
    products.push(ProductType.Shorts)
    products.push(ProductType.NativeCap)
    

    products.push(ProductType.TraditionalSuit)
    products.push(ProductType.IsiAguSash)
    products.push(ProductType.IsiAguTop)
    products.push(ProductType.Safari)
    products.push(ProductType.JacketDress)
    products.push(ProductType.CummerBand)


        // // NEW PRODUCTS ASKED BY ATAFO,
        // TraditionalSuit: "TRADITIONAL SUIT",
        // IsiAguSash: "ISI AGU SASH",
        // IsiAguTop: "ISI AGU TOP",
        // Safari: "SAFARI",
        // JacketDress: "JACKET DRESS",
        // // BowTie: "BOW TIE",
        // CummerBand: "CUMMERBAND",


    products.sort(function (a, b) {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
    })

    return products
}


export const ProductType_options_NonFormik = (name, classNames, onChangeFunc, value, showNone) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>

            { showNone ? <option>{ProductType.None}</option> : null }

            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}


        </select>
    )
}


export const ProductType_options = (name, classNames, onChangeFunc) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames} onChange={onChangeFunc}>
            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}
        </Field>
    )
}


export const productType_isGroup = (type) => {
    switch (type) {
        case ProductType.Suit2piece: case ProductType.Suit3piece: case ProductType.KurtaPyjama: case ProductType.Sherwani: case ProductType.IndoWestern: case ProductType.Tuxedoe: case ProductType.SafariSuit: return true
        default: return false
    }
}


export const productType_groupProducts = (type) => {
    switch (type) {
        case ProductType.Sherwani: return [ProductType.Sherwani, ProductType.Kurta, ProductType.Pyjama]
        case ProductType.Suit2piece: return [ProductType.Coat, ProductType.Trouser]
        case ProductType.Suit3piece: return [ProductType.Coat, ProductType.Trouser, ProductType.WaistCoat]
        case ProductType.KurtaPyjama: return [ProductType.Kurta, ProductType.Pyjama]
        case ProductType.IndoWestern: return [ProductType.IndoWestern, ProductType.Trouser]
        case ProductType.Tuxedoe: return [ProductType.Tuxedoe, ProductType.Trouser]
        case ProductType.SafariSuit: return [ProductType.SafariSuit, ProductType.Trouser]
        default: return []
    }
}


export const StyleSelectionProductType_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select has-background-grey-dark has-text-white ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{"All"}</option>
            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}

        </select>
    )
}